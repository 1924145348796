<template>
    <div class="login">
        <!-- 背景图 -->
        <el-image :src="require('@/assets/Login/bg2.jpg')" class="login-banimg"></el-image>
        <div class="login-content">
            <!-- 左边文字 -->
            <div class="login-left">
                <div class="login-title">驱动型绩效管理平台</div>
                <div class="login-text">绩效管理一体化解决方案 绩效智慧大数据</div>
                <ul>
                    <li><i class="el-icon-caret-right"></i>绩效指标库</li>
                    <li><i class="el-icon-caret-right"></i>绩效分配方案</li>
                    <li><i class="el-icon-caret-right"></i>绩效指标应用</li>
                    <li><i class="el-icon-caret-right"></i>绩效模拟与测算</li>
                    <li><i class="el-icon-caret-right"></i>医疗服务项目标化价值</li>
                </ul>
            </div>
            <!-- 右边登录 -->
            <div class="login-form">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="密码登录" name="first" class="login-tab">
                        <el-form :model="loginmodelForm" :rules="loginrules" ref="loginrefForm">
                            <el-form-item prop="username">
                                <el-input type="text" v-model="loginmodelForm.username" auto-complete="off" placeholder="请输入用户名/手机号" clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input type="password" v-model="loginmodelForm.password" placeholder="密码" @keyup.enter.native="loginClic" :loading="logining"></el-input>
                            </el-form-item>
                            <!-- <el-checkbox v-model="checked" class="rememberme"
                >记住密码</el-checkbox
              > -->
                            <el-form-item>
                                <el-button class="login-primary" type="primary" @click="loginClic">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="手机验证码登录" name="second" class="login-tab">
                        <el-form :model="loginYzmlForm" :rules="loginYzmrules" ref="loginrefYzmForm">
                            <el-form-item prop="phone">
                                <el-input v-model="loginYzmlForm.phone" type="text" auto-complete="off" placeholder="手机号" clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="yzm" class="yzm">
                                <el-input v-model="loginYzmlForm.yzm" type="password" placeholder="验证码" class="login-yzm" @keyup.enter.native="loginYzm"></el-input>
                                <el-button :disabled="isClick" :class="[isClick ? 'login-isgetyzm' : 'login-getyzm']" class="" @click="sms">{{ yzmcontent }}</el-button>
                            </el-form-item>
                            <div style="height: 19px"></div>
                            <el-form-item>
                                <el-button class="login-primary" type="primary" @click="loginYzm">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
                <!-- <div class="login-pass">忘记密码</div> -->
            </div>
        </div>
        <!-- 底部 -->
        <div class="login-bottom">
            2018 All Rights Reserved. 鼎立华康（北京）健康管理有限公司 备案号:
            京ICP备18001073号-3 京公网安备 11010802027953号
        </div>
    </div>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("不能为空"));
            }
            setTimeout(() => {
                if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                } else {
                    callback();
                }
            }, 100);
        };
        return {
            logining: false,
            // 有没有点击获取验证码
            isClick: false,
            checked: true,
            // 验证码的文字
            yzmcontent: "获取验证码",
            // 账号密码登录
            loginmodelForm: {
                username: "",
                password: "",
                phone: "",
            },
            loginrules: {
                username: [
                    {
                        required: true,
                        message: "请输入账号",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
            },
            // 验证码登录
            loginYzmlForm: {
                phone: "",
                yzm: "",
            },
            loginYzmrules: {
                phone: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
            },
            // 密码登录和手机验证码登录
            activeName: "first",
            timer: null, // 定时器名称
        };
    },
    mounted() {
        this.account(); //获取cookie的方法
    },
    methods: {
        // 切换的时候是手机号的时候显示出来
        handleClick(tab) {
            if (tab.index == 1) {
                if (this.loginmodelForm.username) {
                    if (
                        this.loginmodelForm.username.length == 11 &&
                        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
                            this.loginmodelForm.username
                        )
                    ) {
                        this.loginYzmlForm.phone = this.loginmodelForm.username;
                    }
                }
            }
        },
        account() {
            if (document.cookie.length >= 0) {
                // console.log(this.getCookie("username"));
                this.loginmodelForm.username = this.getCookie("username");
                this.loginmodelForm.password = this.getCookie("password");
            }
        },
        setCookie(c_name, c_pwd, exdate) {
            //账号，密码 ，过期的天数
            var currentDate = new Date();
            currentDate.setTime(
                currentDate.getTime() + 24 * 60 * 60 * 1000 * exdate
            ); //保存的天数
            document.cookie =
                "username=" +
                c_name +
                ";path=/;expires=" +
                currentDate.toLocaleString();
        },
        getCookie(name) {
            var arr = document.cookie.split(";");
            //["_ga=GA1.1.1756734561.1561034020", " mobile=123" password=456"]
            for (var i = 0; i < arr.length; i++) {
                var arr2 = arr[i].split("="); // ["_ga", "GA1.1.1756734561.1561034020"]
                if (arr2[0].trim() == name) {
                    return arr2[1];
                }
            }
        },
        clearCookie() {
            this.setCookie("", "", -1); //清除cookie
        },
        // 点击获取验证码
        async sms() {
            this.$refs.loginrefYzmForm.validate(async (valid) => {
                if (valid) {
                    let snum = 60;
                    this.timer = setInterval(() => {
                        snum--;
                        this.yzmcontent = "重新获取(" + snum + "s)";
                        this.isClick = true;
                        if (snum === 0) {
                            clearInterval(this.timer);
                            this.yzmcontent = "获取验证码";
                            this.isClick = false;
                            return;
                        }
                    }, 1000);
                    let data = _qs.stringify({
                        phone: this.loginYzmlForm.phone,
                    });
                    let { data: res } = await this.$axios.sms(data);
                    // console.log(res);
                    if (res.code == 200) {
                        this.$message({
                            message: "获取验证码成功",
                            type: "success",
                        });
                    } else if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 500) {
                        this.$message({
                            message: "获取验证码失败",
                            type: "error",
                        });
                    }
                } else {
                    console.log("error submit!");
                    return false;
                }
            });
        },
        // 验证码登录
        async loginYzm() {
            if (this.loginYzmlForm.yzm == "") {
                this.$message.error("请输入验证码");
            }
            var data = {
                password: this.loginYzmlForm.yzm,
                username: this.loginYzmlForm.phone,
                type: 1,
            };
            let res = await this.$axios.login(data);
            // console.log(res);
            window.sessionStorage.setItem("token", res.data.token);
            window.sessionStorage.setItem("nickName", res.data.nickName);
            // 手机号
            window.sessionStorage.setItem("phone", res.data.phone);
            // 医院id
            window.sessionStorage.setItem("hospitalId", res.data.hospitalId);
            // 科室id
            window.sessionStorage.setItem(
                "departmentId",
                res.data.departmentId
            );
            // 是否是第一次登录 0 是  1 不是
            window.sessionStorage.setItem("isFirst", res.data.isFirst);
            // 0 医院用户   1 科室用户
            window.sessionStorage.setItem("type", res.data.type);
            // 数据维护权限
            window.sessionStorage.setItem("dataAuth", res.data.dataAuth);
            // 医院名称
            window.sessionStorage.setItem(
                "hospitalName",
                res.data.hospitalName
            );
            // 科室名称
            window.sessionStorage.setItem(
                "departmentName",
                res.data.departmentName
            );
            // 绩效权限
            window.sessionStorage.setItem(
                "performanceAuth",
                res.data.performanceAuth
            );
            window.sessionStorage.setItem("roleName", res.data.roleName);
            if (res.data.code == 200) {
                this.$router.push({ path: "/index" });
                this.$message({
                    message: "登录成功！",
                    type: "success",
                });
            } else if (res.data.code == 500) {
                this.$message.error("验证码错误！,请重新获取");
                this.yzmcontent = "获取验证码";
                clearInterval(this.timer);
                this.loginYzmlForm.yzm = "";
            }
        },
        // 点击登录
        loginClic() {
            this.$refs.loginrefForm.validate(async (valid) => {
                if (valid) {
                    this.logining = true;
                    if (this.checked == true) {
                        //存入cookie
                        this.setCookie(this.loginmodelForm.username, 7); //保存7天
                    } else {
                        this.clearCookie();
                    }
                    var data = {
                        username: this.loginmodelForm.username,
                        password: this.loginmodelForm.password,
                        type: 0,
                    };
                    let res = await this.$axios.login(data);
                    // console.log(res);
                    window.sessionStorage.setItem("token", res.data.token);
                    window.sessionStorage.setItem(
                        "nickName",
                        res.data.nickName
                    );

                    // 手机号
                    window.sessionStorage.setItem("phone", res.data.phone);
                    // 医院id
                    window.sessionStorage.setItem(
                        "hospitalId",
                        res.data.hospitalId
                    );
                    // 科室id
                    window.sessionStorage.setItem(
                        "departmentId",
                        res.data.departmentId
                    );
                    // 是否是第一次登录 0 是  1 不是
                    window.sessionStorage.setItem("isFirst", res.data.isFirst);
                    // 0 医院用户   1 科室用户
                    window.sessionStorage.setItem("type", res.data.type);
                    // 数据维护权限
                    window.sessionStorage.setItem(
                        "dataAuth",
                        res.data.dataAuth
                    );
                    // 医院名称
                    window.sessionStorage.setItem(
                        "hospitalName",
                        res.data.hospitalName
                    );
                    // 科室名称
                    window.sessionStorage.setItem(
                        "departmentName",
                        res.data.departmentName
                    );
                    // 绩效权限
                    window.sessionStorage.setItem(
                        "performanceAuth",
                        res.data.performanceAuth
                    );
                    window.sessionStorage.setItem(
                        "roleName",
                        res.data.roleName
                    );
                    if (res.data.code == 200) {
                        this.$router.push({ path: "/index" });
                        this.$message({
                            message: "登录成功！",
                            type: "success",
                        });
                        this.getRouters();
                    } else if (res.data.code == 500) {
                        this.$message.error("账号或密码错误！");
                    }
                } else {
                    console.log("error submit!");
                    return false;
                }
            });
        },
        // 菜单权限
        async getRouters() {
            let res = await this.$axios.getRouters();
            // console.log(res);
            this.$store.commit("saveRoute", JSON.stringify(res.data.data));
            // console.log(this.$store.state.routeName);
        },
    },
};
</script>

<style lang="less" scoped>
.login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.login-form {
    position: relative;
    width: 400px;
    height: 330px;
    background: #fff;
    border: 1px solid #d6d3d3;
}
.login-content {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
    .login-pass {
        font-size: 14px;
        color: black;
        text-align: right;
        width: 80%;
        margin: 0 auto;
        margin-top: -43px;
    }
    .login-right {
        position: relative;
        width: 350px;
        padding: 35px 35px 15px;
        background: #fff;
        border: 1px solid #d6d3d3;
    }
    .login-left {
        position: relative;
        text-align: left;
        .login-title {
            font-size: 50px;
            margin-bottom: 20px;
        }
        .login-text {
            font-size: 40px;
            margin-bottom: 20px;
        }
        ul {
            list-style: none;
            font-size: 20px;
        }
        ul > li {
            margin-bottom: 10px;
        }
    }
}

/deep/ .el-tabs__nav {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

/deep/ .el-tabs__item {
    width: 160px;
    padding: 0px;
}
/deep/ .el-tabs__header {
    width: 80%;
    margin: 0 auto;
    border: 1px solid #dcdfe6;
    margin-top: 20px;
    margin-bottom: 20px;
}

/deep/ .el-tabs__nav-wrap::after {
    background: transparent;
}
/deep/ .el-tabs__item.is-active {
    color: white;
    background: #0380dd;
}
/deep/ [data-v-657094ba] .el-tabs__item {
    width: 160px;
    padding: 0;
}
/deep/ .el-tabs__item:hover {
    color: black;
}

/deep/ .el-tabs__active-bar {
    background: transparent;
}

.login-tab {
    width: 80%;
    height: 250px;
    margin: 0 auto;
}

.rememberme {
    float: left;
}

.login-primary {
    width: 100%;
    position: relative;
    top: 10px;
}
.yzm {
    display: flex;
    flex-direction: row;
    .login-yzm {
        width: 60%;
    }
    .login-getyzm {
        width: 40%;
        background: #0380dd;
        color: white;
    }
    .login-isgetyzm {
        width: 40%;
        background: #ccc;
        color: white;
    }
}

.el-tabs--border-card {
    border: none;
    box-shadow: none;
}

.login-banimg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.login-bottom {
    width: 100%;
    position: fixed;
    bottom: 10px;
    font-size: 13px;
    text-align: center;
    color: #666;
}
</style>